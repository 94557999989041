
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import CardNumberUserFreeToMembership from '@/components/CardNumberUserFreeToMembership';
import Membership from '@/components/membership';
import StudyTimeReport from '@/components/StudyTimeReport';
import CompletedTest from '@/components/testCompleted';
import UnCompletedTest from '@/components/testUnCompleted';
import { Box } from '@mui/material';

function Dashboard() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'center' }}>
      <CardNumberUserFreeToMembership />
      <Membership />
      <CompletedTest />
      <StudyTimeReport />
      <UnCompletedTest />
    </Box>
  );
}
export default Dashboard;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/dashboard/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  